.simulateur{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.simulateur2{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
}
.simulateur__item{
    margin-right: 20px;
    margin-top: 20px;
    flex: 1;
}

.item_contenu{
    display: flex;
    flex-direction: row;
    padding: 20px;
}
.item__prime{
    margin-left: 20px;
    padding: 10px;
    width: 200px;
}
.item__buttons{
    display: flex;
    justify-content: center;
}

.slist{
    display: flex;
    padding: 10px;
    flex-direction: column;
}
.slist__corps{
    display: flex;
}